
.trigger {
  font-size: 18px;
  margin-left: 18px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  margin: 8px 0;
  img{
    max-width: 60%;
  }
}
.content{
  margin: 24px 16px;
  padding: 24px;
  min-height: calc(100vh - 112px);
}
.header{
  display: flex;
  justify-content: space-between;
  padding-right: 24px;
}
.quitBtn{
    text-align: center;
}
