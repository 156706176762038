.devider{
  margin: 14px 0;
}
.formBox{
  display: flex;
  justify-content: center;
  .form{
    width: 600px
  }
}
