.container{
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #efefef;
  .content{
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px -4px rgba(0,0,0,.1), 0 6px 16px 0 rgba(0,0,0,.08), 0 12px 24px 16px rgba(0,0,0,.04);
    .logoBox{
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
      font-weight: bold;
      img{
        width: 48px;
        margin-right: 10px;
      }
    }
  }
  .loginForm{
    width: 300px;
  }
}
