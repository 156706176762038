.filterBox{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.filterWidth{
  width: 350px;
}
.devider{
  margin: 14px 0;
}
