.devider{
  margin: 14px 0;
}
.loading{
  text-align: center;
  font-size: 50px;
  color: #1677ff;
}
.content{
  display: flex;
  flex-wrap: wrap;
  .contentItem{
    display: flex;
    flex-basis: 250px;
    flex-direction: column;
    justify-content: space-between;
    height: 162px;
    padding: 14px;
    border-radius:10px;
    margin:10px 6px;
    box-shadow: 2px 2px 8px rgba(0,0,0,.2);
    .title{
      font-weight: bold;
      font-size: 16px;
    }
    .defaultTips{
      color:rgba(0,0,0, 0.6);
      text-indent: 2em;
      padding: 10px;
    }
    .operation{
      display: flex;
      justify-content:flex-end;
      align-items: center;
    }
  }
  .addBtn{
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .addIcon{
    font-size: 40px;
    color: rgba(0,0,0, 0.6)
  }
}
